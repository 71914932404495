<template>
	<div>
		Updating in {{ countdown }}s...
		<dygraphs v-if="opts" :key="refresh" :data="data.data" :options="opts" />
		<div class="card card-custom card-stretch gutter-b mt-5">
			<div class="card-body">
				<b-table
					:items="data.rawdata"
					:fields="data.fields"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="currentPage"
					:per-page="perPage"
				>
					<template #head(rx_tcp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[0]" @change="boxClicked()"
							>RX TCP</b-form-checkbox
						></template
					>
					<template #head(rx_udp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[1]" @change="boxClicked()"
							>RX UDP</b-form-checkbox
						></template
					>
					<template #head(rx_icmp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[2]" @change="boxClicked()"
							>RX ICMP</b-form-checkbox
						></template
					>
					<template #head(rx_igmp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[3]" @change="boxClicked()"
							>RX IGMP</b-form-checkbox
						></template
					>
					<template #head(rx_http) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[4]" @change="boxClicked()"
							>RX HTTP</b-form-checkbox
						></template
					>
					<template #head(rx_other) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[5]" @change="boxClicked()"
							>RX Other</b-form-checkbox
						></template
					>
					<template #head(rx_total) v-if="opts && opts.visibility"
						><b-form-checkbox
							v-model="opts.visibility[opts.visibility.length > 10 ? 6 : 3]"
							@change="boxClicked()"
							>Rx Total</b-form-checkbox
						></template
					>
					<template #head(tx_tcp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[7]" @change="boxClicked()"
							>TX TCP</b-form-checkbox
						></template
					>
					<template #head(tx_udp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[8]" @change="boxClicked()"
							>TX UDP</b-form-checkbox
						></template
					>
					<template #head(tx_icmp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[9]" @change="boxClicked()"
							>TX ICMP</b-form-checkbox
						></template
					>
					<template #head(tx_igmp) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[10]" @change="boxClicked()"
							>TX IGMP</b-form-checkbox
						></template
					>
					<template #head(tx_http) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[11]" @change="boxClicked()"
							>TX HTTP</b-form-checkbox
						></template
					>
					<template #head(tx_other) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[12]" @change="boxClicked()"
							>TX Other</b-form-checkbox
						></template
					>
					<template #head(tx_total) v-if="opts && opts.visibility"
						><b-form-checkbox
							v-model="opts.visibility[opts.visibility.length > 10 ? 13 : 7]"
							@change="boxClicked()"
							>TX Total</b-form-checkbox
						></template
					>
					<template #head(rx_reliable) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[0]" @change="boxClicked()"
							>Up Reliable</b-form-checkbox
						></template
					>
					<template #head(rx_unreliable) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[1]" @change="boxClicked()"
							>Up Unreliable</b-form-checkbox
						></template
					>
					<template #head(rx_oob) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[2]" @change="boxClicked()"
							>Up OOB</b-form-checkbox
						></template
					>

					<template #head(tx_reliable) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[4]" @change="boxClicked()"
							>Down Reliable</b-form-checkbox
						></template
					>
					<template #head(tx_unreliable) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[5]" @change="boxClicked()"
							>Down Unreliable</b-form-checkbox
						></template
					>
					<template #head(tx_oob) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[6]" @change="boxClicked()"
							>Down OOB</b-form-checkbox
						></template
					>
					<template #head(current_mc_idx) v-if="opts && opts.visibility"
						><b-form-checkbox v-model="opts.visibility[8]" @change="boxClicked()"
							>MODCOD</b-form-checkbox
						></template
					>
					<template #cell(timestamp)="data">{{ dateFormat(data.item.timestamp) }} </template>
					<template #cell(current_mc_idx)="data">{{
						MODCODS[data.item.current_mc_idx].name
					}}</template>
				</b-table>
			</div>
			<div class="footer" v-if="data.rawdata.length > 0 && data.rawdata.length > perPage">
				<b-pagination
					class="pagination"
					v-model="currentPage"
					:total-rows="data.rawdata.length"
					:per-page="perPage"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { MODCODS } from '@/helpers';

export default {
	props: ['data'],
	data() {
		return {
			sortBy: 'timestamp',
			sortDesc: true,
			countdown: 0,
			opts: null,
			refresh: 0,
			MODCODS: null,
			perPage: 10,
			currentPage: 1,
		};
	},
	components: { dygraphs: () => import('@/view/content/lib/dygraphs.vue') },
	methods: {
		dateFormat(dt) {
			return (
				new Date(dt).toISOString().split('T')[0] +
				' ' +
				new Date(dt).toISOString().split('T')[1].substring(0, 8)
			);
		},
		boxClicked() {
			this.refresh++;
		},
	},
	created() {
		this.MODCODS = MODCODS;
	},
	watch: {
		data: {
			deep: true,
			handler(d) {
				this.countdown = d.countdown;
				this.opts = d.opts;
				this.refresh++;
			},
		},
	},
};
</script>

<style>
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
</style>